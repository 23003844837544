// Base
@import "feelbettr/variables.less";
@import "themes/uikit-min-theme-default.less";
@import "themes/base.less";
@import "themes/typography.less";
@import "themes/contact.less";
@import "themes/header-overlay.less";

@bg-color: #fff;
@link-hover-color: @primary;
@global-font-size: 14px;
@global-font-family: sans-serif;
@red: #EA3344;
@primary: @teal;
@secondary: @orange;
@color-initial: #ffffff;
@color-primary: @primary;
@color-secondary: @secondary;
@color-tertiary: darken(@primary, 10%);
@color-quaternary: @border-color;
@text-color:#000;
@teal: #129598;
@brown: #A1896D;
@aqua: #099799;
@blue: @aqua;
@green: #77B95D;

@orange: #F26D35;
@dark: #2C3E50;
@grey: #f1f1f1;
@dark-grey: darken(@grey, 20%);
@dark:  darken(@grey, 40%);
@purple: #7364B2;

@border-color:@grey;
@border-radius: 3px;
@text-color:#000;
@heading-color: #1A2125;
@gutter: 30px;

@success:#BBD86B;
@error: #EA3344;
@notice: #FFCB09;
@warning: #FF5E35;

@phone-max: 680px;
@tablet-max: 787px;
@desktop: 1280px;
@grid-collapse: 787px;

body,html {
	background:@bg-color !important;
}

#header-container {
	position:relative;
	z-index:100;
	background:none !important;
}
#background-image {
	background: #fff url(https://res.cloudinary.com/feelbettr/image/upload/r_0/585/smoke-rainbow_pkxirg) no-repeat 0 -950px !important;
    height: 300px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.first-row-with-padding {
	background: none !important
}

.is-wrapper .is-section:first-child,
.content-row:first-child {
	background:none !important;
	padding-top:50px;
	border-top:0;
}

h3 {
	span {
		background:@bg-color
	}
}

li[data-id="buy-tickets"] span {
	background:rgb(236, 64, 122);
	padding:10px;
	color: #fff;
    box-shadow: 4px 4px 0 0 rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.2);
	margin-top:-5px;

	&:hover {
		background:#5D6D7E;
		color:#fff;
	}
}

.btn.call-to-action {
	background: rgb(236, 64, 122) !important
}

.uk-height-small {
	padding-top:30px;
	height:100px !important;
}

.first-row-with-padding {
	background:none !important
}

h2.line-behind {
	position:relative;
	background:#fff;
	
	span {
		background:#fff;
		display:inline-block;
		position:relative;
		z-index:2;
		padding:0 15px 0 15px;
		font-size:inherit;
		text-transform: uppercase
	}

	&:after {
		content:"";
		height:1px;
		background:rgba(0,0,0,0.1);
		width:100%;
		position:absolute;
		left:0;
		top:55%;
		z-index:1;
	}
}

footer.uk-light {
	a {
		color:#fff
	}
}

.is-container {
	img {
		box-shadow: 10px 10px 0 0 rgba(0,0,0,0.08);
    	border: 4px solid rgba(255,255,255,0.2);
    	border-radius: 3px;

    	&.no-shadow {
			box-shadow:none
		}
	}
}

// .is-home {
	.is-section {
		// transform: skew(0deg, -1deg);
		padding:30px 0;
		border-top: 10px solid rgba(0,0,0,0.06)
	}

	.is-container {
		// transform: skew(0deg, 1deg);
	}

// }

.is-section {
	position: relative;
	z-index:2;
}

.uk-card.uk-card-default {
	// box-shadow: 10px 10px 0 0 rgba(0,0,0,0.08);
}

.uk-button {
	box-shadow: 4px 4px 0 0 rgba(0,0,0,0.08);
	border: 1px solid rgba(255,255,255,0.2);
}

.uk-label.uk-position-top-right {
    top: 10px;
    right: 10px;
    background: rgba(0,0,0,0.5);
}

.filter-nav {
	margin-bottom:40px
}

a.uk-button, 
button, 
.uk-button-primary {
	&.btn-outline {
		background:none !important;
	}
}